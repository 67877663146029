import { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useRouter } from 'next/router';
import { parseQueryStringToObject } from '@utils/queryUtils';
import useIsOnClient from './useIsOnClient';

export const useCurrentQuery = () => {
  const { query } = useRouter();

  const isClient = useIsOnClient();

  const currentQuery = useMemo(() => {
    const { partner, ...rest } = query;

    return Object.keys(rest).length === 0 &&
      typeof window !== 'undefined' &&
      isClient
      ? parseQueryStringToObject(window.location.search)
      : rest;
  }, [query, isClient]);

  return {
    currentQuery,
  };
};
