/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Offer API
 * OpenAPI spec version: v14
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import useCustomInstance from '../fetchers/useCustomInstance';
export type GetSummaryParams = {
/**
 * Id of order to get summary for
 */
order_id: string;
/**
 * Last name from the the order to get summary for.
 */
last_name?: string;
};

export type GetOrderIdUpsellUpsellOrderIdPollParams = {
/**
 * Last name
 */
last_name?: string;
};

export type PostOrderIdUpsellBookParams = {
/**
 * Last name
 */
last_name?: string;
};

export type PatchOrderIdUpsellServicesParams = {
/**
 * Last name
 */
last_name?: string;
/**
 * Currency of display
 */
curr: string;
};

export type GetOrderIdUpsellParams = {
/**
 * Last name
 */
last_name?: string;
/**
 * Currency of display
 */
curr: string;
};

export type GetOrderIdUpsellNewParams = {
/**
 * Last name
 */
last_name?: string;
/**
 * Currency of display
 */
curr: string;
};

export type PostOrderIdChallengeCallbackParams = {
/**
 * Challenge id (provider transaction id)
 */
challenge_id?: number;
};

export type GetDeeplinkChargeBreakdownParams = {
/**
 * example: 21.99-EUR-1336--27.95-CHF-1336
 */
fares: string;
/**
 * Dohop connection fee
 */
fee?: string;
/**
 * Currency of display
 */
curr: string;
};

export type PatchOfferIdBundlesParams = {
/**
 * Id of bundle to select
 */
bundle_id: string;
};

export type PostNewParams = {
/**
 * Residency
 */
res: string;
/**
 * Currency of display
 */
curr: string;
/**
 * Language
 */
lang?: string;
get_fare_bundles?: boolean;
/**
 * Partner name
 */
mbs_partner: string;
/**
 * example: 0-1
 */
combo: string;
/**
 * example: 21.99-EUR-1336--27.95-CHF-1336
 */
fares: string;
/**
 * Outbound itineraries, example: BCNGVA202111191445016250140U21408---GVAAMS202111191845020300145U21357
 */
out: string;
/**
 * Homebound itineraries
 */
home?: string;
/**
 * Dohop connection fee
 */
fee?: string;
total_fee?: string;
/**
 * n_adults or passenger_ages must be set, but they can not be used together
 */
n_adults?: number;
/**
 * Can not be used with n_adults or youngster_ages
 */
passenger_ages?: string;
/**
 * Can only be used with n_adults, not with passenger_ages
 */
youngsters_ages?: string;
utm_source?: string;
utm_content?: string;
utm_medium?: string;
utm_campaign?: string;
'ticketing-partner'?: string;
tracking_id?: string;
issued_at?: string;
/**
 * deeplink signature parameter
 */
sig?: string;
/**
 * Allow cross vendor alignment of bundles
 */
allow_cross_vendor_alignment?: boolean;
/**
 * Allow cross vendor alignment of services
 */
allow_cross_vendor_service_alignment?: boolean;
/**
 * Single transaction. Indication that we should condense a few transactions into one
 */
allow_single_transaction?: boolean;
/**
 * Whether to use the new tiers. This will be used to A/B test the new tiers. Can be removed after test concludes.
 */
allow_new_tiers?: boolean;
/**
 * Prefetch coming from search page
 */
search_prefetch?: boolean;
};

export type GetNewParams = {
/**
 * Residency
 */
res: string;
/**
 * Currency of display
 */
curr: string;
/**
 * Language
 */
lang?: string;
get_fare_bundles?: boolean;
/**
 * Partner name
 */
mbs_partner: string;
/**
 * example: 0-1
 */
combo: string;
/**
 * example: 21.99-EUR-1336--27.95-CHF-1336
 */
fares: string;
/**
 * Outbound itineraries, example: BCNGVA202111191445016250140U21408---GVAAMS202111191845020300145U21357
 */
out: string;
/**
 * Homebound itineraries
 */
home?: string;
/**
 * Dohop connection fee
 */
fee?: string;
total_fee?: string;
/**
 * n_adults or passenger_ages must be set, but they can not be used together
 */
n_adults?: number;
/**
 * Can not be used with n_adults or youngster_ages
 */
passenger_ages?: string;
/**
 * Can only be used with n_adults, not with passenger_ages
 */
youngsters_ages?: string;
utm_source?: string;
utm_content?: string;
utm_medium?: string;
utm_campaign?: string;
'ticketing-partner'?: string;
tracking_id?: string;
issued_at?: string;
/**
 * deeplink signature parameter
 */
sig?: string;
/**
 * Allow cross vendor alignment of bundles
 */
allow_cross_vendor_alignment?: boolean;
/**
 * Allow cross vendor alignment of services
 */
allow_cross_vendor_service_alignment?: boolean;
/**
 * Single transaction. Indication that we should condense a few transactions into one
 */
allow_single_transaction?: boolean;
/**
 * Whether to use the new tiers. This will be used to A/B test the new tiers. Can be removed after test concludes.
 */
allow_new_tiers?: boolean;
/**
 * Prefetch coming from search page
 */
search_prefetch?: boolean;
};

export type GetItineraryParams = {
lang?: string;
/**
 * example STNSAW202310091645022250340PC1166---SAWTBS202310100010003200210PC314
 */
out: string;
/**
 * example STNSAW202310091645022250340PC1166---SAWTBS202310100010003200210PC314
 */
home?: string;
combo: string;
};

export type ServerPersesModelsErrorResponseErrorPaymentErrorResponseCode = typeof ServerPersesModelsErrorResponseErrorPaymentErrorResponseCode[keyof typeof ServerPersesModelsErrorResponseErrorPaymentErrorResponseCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ServerPersesModelsErrorResponseErrorPaymentErrorResponseCode = {
  NUMBER_402: 402,
} as const;

/**
 * Something went wrong during fetching card info
 */
export interface ServerPersesModelsErrorResponseErrorPaymentError {
  description?: string;
  error_code: ErrorCode;
  recoverable?: boolean;
  response_code?: ServerPersesModelsErrorResponseErrorPaymentErrorResponseCode;
  status_text: StatusText;
}

export interface PollingRefreshMismatchError {
  error_code: string;
  initial_price: Price;
  is_recoverable: boolean;
  new_price: Price;
}

export type PollResponseBookingsItem = TravelBooking | ServiceBooking;

export interface PollResponse {
  bookings: PollResponseBookingsItem[];
  challenge?: ChallengeInfo;
  error?: PollResponseError;
  keep_polling: boolean;
  order_id: string;
  total: Price;
}

export interface PollingPaymentError {
  error_code: string;
  is_recoverable: boolean;
  user_information?: string;
}

export type ServerPersesAppsOfferV14SchemasUpsellPollResponseError = Error | PollingRefreshMismatchError | PollingPaymentError;

export interface ServerPersesAppsOfferV14SchemasUpsellPollResponse {
  bookings: ServiceBooking[];
  challenge?: ChallengeInfo;
  error?: ServerPersesAppsOfferV14SchemasUpsellPollResponseError;
  keep_polling: boolean;
}

export type PollResponseError = Error | PassengerError | PollingRefreshMismatchError | BillingAddressError | PollingPaymentError;

export interface ServerPersesAppsOfferV14SchemasOfferRefreshMismatchError {
  initial_price: Price;
  new_price: Price;
}

export interface UpsellOfferResponse {
  all_pax_services: DohopService[];
  order_id: string;
}

export interface UpliftPaymentMethod {
  payment_method?: PaymentMethod;
}

export type UpdateOfferPassengerFrequentFlyerNumbers = {[key: string]: string};

export interface UpdateOfferPassenger {
  date_of_birth?: string;
  first_name?: string;
  frequent_flyer_numbers?: UpdateOfferPassengerFrequentFlyerNumbers;
  gender?: PaxGender;
  last_name?: string;
  passenger_id: string;
  passport_country?: string;
  passport_expiration?: string;
  passport_number?: string;
  title?: string;
  traveling_with?: string;
}

export interface UpdatePassengersRequest {
  passengers: UpdateOfferPassenger[];
}

export type TravelBookingBookingType = typeof TravelBookingBookingType[keyof typeof TravelBookingBookingType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TravelBookingBookingType = {
  travel: 'travel',
} as const;

export interface TravelBooking {
  booking_id: string;
  booking_type?: TravelBookingBookingType;
  fare_code: string;
  legs: Leg[];
  pnr?: string;
  status: PollingBookingStatus;
  total: Price;
}

/**
 * An enumeration.
 */
export type TransportType = typeof TransportType[keyof typeof TransportType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransportType = {
  AIRPORT: 'AIRPORT',
  AIRPORT_AND_TRAIN: 'AIRPORT_AND_TRAIN',
  TRAIN: 'TRAIN',
} as const;

export interface TransportStation {
  code: string;
  description: string;
  name: string;
  station_type: string;
}

export interface TransportLeg {
  arrival_station: TransportStation;
  departure_station: TransportStation;
}

export interface TransportFareFeatures {
  description: string;
  name: string;
}

export type TotalType = typeof TotalType[keyof typeof TotalType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TotalType = {
  final: 'final',
} as const;

export interface Total {
  amount: string;
  label: string;
  type?: TotalType;
}

export interface TokenizedCardDetails {
  masked_card: string;
  pci_proxy_card_token: string;
  pci_proxy_cvc_token: string;
}

/**
 * An enumeration.
 */
export type TierName = typeof TierName[keyof typeof TierName];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TierName = {
  base: 'base',
  commission: 'commission',
  light: 'light',
  premium: 'premium',
  protected: 'protected',
  protected_plus: 'protected_plus',
} as const;

export interface SwallowedRefreshMismatchError {
  hidden_mismatch: boolean;
  swallowed_amount: Price;
}

export interface SummaryPriceBreakdown {
  total_adult: Price;
  total_child: Price;
  total_infant: Price;
  total_tax: Price;
}

export type SummaryOtherServicesItem = BookedAdditionalLoungeAccess | AdditionalSecurityFastTrack | DohopServiceBase | AdditionalServiceBase;

export interface Summary {
  leg_summaries: LegGroupSummary[];
  other_services: SummaryOtherServicesItem[];
  price_breakdown: SummaryPriceBreakdown;
  total: Price;
}

/**
 * An enumeration.
 */
export type StatusText = typeof StatusText[keyof typeof StatusText];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StatusText = {
  Incomplete: 'Incomplete',
  Complete: 'Complete',
  NotProcessed: 'NotProcessed',
  Unknown: 'Unknown',
} as const;

export type ServiceGroupServicesItem = AdditionalLuggage | SelectedSeat;

export interface ServiceGroup {
  carrier_codes: string[];
  iatas: string[];
  legs: Leg[];
  passenger_id: string;
  services: ServiceGroupServicesItem[];
}

/**
 * An enumeration.
 */
export type ServiceClass = typeof ServiceClass[keyof typeof ServiceClass];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ServiceClass = {
  alyzia: 'alyzia',
  bag_drop: 'bag_drop',
  bistro_voucher: 'bistro_voucher',
  cancellation_protection: 'cancellation_protection',
  checked_bags_for_fee: 'checked_bags_for_fee',
  check_in_at_counters: 'check_in_at_counters',
  comfort_packs: 'comfort_packs',
  comfort_packs_comfort_kit: 'comfort_packs_comfort_kit',
  comfort_packs_priority_service: 'comfort_packs_priority_service',
  discount_baggage_discount: 'discount_baggage_discount',
  discount_child_ticket_discount: 'discount_child_ticket_discount',
  dohop_service_fee: 'dohop_service_fee',
  dohop_commission_fee: 'dohop_commission_fee',
  exclusive_cabin: 'exclusive_cabin',
  flight_cancellation_mismatch: 'flight_cancellation_mismatch',
  flight_cancel_with_fee: 'flight_cancel_with_fee',
  flight_changes_with_fee: 'flight_changes_with_fee',
  flight_change_mismatch: 'flight_change_mismatch',
  free_flight_cancel: 'free_flight_cancel',
  free_flight_changes: 'free_flight_changes',
  free_route_changes: 'free_route_changes',
  free_ticket_changes: 'free_ticket_changes',
  ground_transport: 'ground_transport',
  itinerary_change: 'itinerary_change',
  life_miles: 'life_miles',
  lounge_access: 'lounge_access',
  loyalty_programs: 'loyalty_programs',
  luggage_cabin: 'luggage_cabin',
  luggage_checked: 'luggage_checked',
  luggage_personal: 'luggage_personal',
  meals: 'meals',
  meals_snack: 'meals_snack',
  meals_standard: 'meals_standard',
  priority_check_in: 'priority_check_in',
  refunds: 'refunds',
  seat_selection: 'seat_selection',
  seat_selection_any: 'seat_selection_any',
  seat_selection_economy: 'seat_selection_economy',
  seat_selection_emergency_exit: 'seat_selection_emergency_exit',
  seat_selection_extra_legroom: 'seat_selection_extra_legroom',
  seat_selection_first_row: 'seat_selection_first_row',
  seat_selection_flatbed: 'seat_selection_flatbed',
  seat_selection_mismatch: 'seat_selection_mismatch',
  seat_selection_plus: 'seat_selection_plus',
  seat_selection_standard: 'seat_selection_standard',
  seat_selection_upfront: 'seat_selection_upfront',
  seat_selection_standard_upfront: 'seat_selection_standard_upfront',
  security_fast_track: 'security_fast_track',
  speedy_boarding: 'speedy_boarding',
  ticket_changes: 'ticket_changes',
  train_ticket: 'train_ticket',
  vip_lounge: 'vip_lounge',
  vip_lounge_and_meal: 'vip_lounge_and_meal',
  children_travel_free: 'children_travel_free',
  city_ticket_included: 'city_ticket_included',
  free_cancellation: 'free_cancellation',
  not_train_specific: 'not_train_specific',
  seat_reservation: 'seat_reservation',
  train_specific_travel: 'train_specific_travel',
  unspecified_default: 'unspecified_default',
} as const;

export type ServiceBookingBookingType = typeof ServiceBookingBookingType[keyof typeof ServiceBookingBookingType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ServiceBookingBookingType = {
  service: 'service',
  commission: 'commission',
} as const;

export type SelectedSeatServiceClass = typeof SelectedSeatServiceClass[keyof typeof SelectedSeatServiceClass];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SelectedSeatServiceClass = {
  seat_selection: 'seat_selection',
} as const;

export type SelectedSeatPrice = PriceWithBreakdown | Price;

export interface SelectedSeat {
  can_decrement: boolean;
  can_increment: boolean;
  col: string;
  direction?: string;
  leg_id: string;
  passenger_id: string;
  price: SelectedSeatPrice;
  quantity: number;
  row: number;
  seat_offer_id: string;
  service_class: SelectedSeatServiceClass;
  service_id: string;
}

export interface SelectServiceRequest {
  quantity: number;
  service_id: string;
}

export interface SelectSeatRequest {
  leg_id: string;
  passenger_id: string;
  seat_offer_id?: string;
}

export interface Segment {
  legs: Leg[];
}

/**
 * convered_connections tells which leg_id pairs are covered by a connection protection policy
 */
export interface SegmentedItineraryWithCoveredConnections {
  covered_connections: CoveredConnection[];
  homebound: Segment[];
  outbound: Segment[];
}

/**
 * An enumeration.
 */
export type SeatSectionAttribute = typeof SeatSectionAttribute[keyof typeof SeatSectionAttribute];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SeatSectionAttribute = {
  temp: 'temp',
} as const;

/**
 * An enumeration.
 */
export type SeatRowAttribute = typeof SeatRowAttribute[keyof typeof SeatRowAttribute];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SeatRowAttribute = {
  exit_row: 'exit_row',
  extra_leg_room: 'extra_leg_room',
  middle_row: 'middle_row',
  wing_row: 'wing_row',
} as const;

export interface SeatMapSeatOffer {
  pax_id: string;
  price: Price;
  seat_offer_id: string;
}

/**
 * An enumeration.
 */
export type SeatMapItemType = typeof SeatMapItemType[keyof typeof SeatMapItemType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SeatMapItemType = {
  display: 'display',
  empty: 'empty',
  exit: 'exit',
  letter: 'letter',
  seat: 'seat',
} as const;

export interface SeatMapDisplayItem {
  item_type: SeatMapItemType;
  text: string;
  width_numerator: number;
}

export type SeatMapRowItemsItem = SeatMapDisplayItem | SeatMapSeat;

export interface SeatMapRow {
  attributes: SeatRowAttribute[];
  items: SeatMapRowItemsItem[];
}

export interface SeatMapSection {
  attributes: SeatSectionAttribute[];
  name: string;
  rows: SeatMapRow[];
}

/**
 * An enumeration.
 */
export type SeatAttribute = typeof SeatAttribute[keyof typeof SeatAttribute];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SeatAttribute = {
  aisle: 'aisle',
  exit: 'exit',
  extra_leg_room: 'extra_leg_room',
  middle: 'middle',
  window: 'window',
  wing_seat: 'wing_seat',
} as const;

export interface SeatMapSeat {
  attributes: SeatAttribute[];
  col: string;
  offers: SeatMapSeatOffer[];
  row: number;
  seat_type: string;
  width_numerator: number;
}

export interface RetryBookOfferRequest {
  contact_information?: ContactInformationRequest;
  origin_url?: string;
  passengers?: UpdateOfferPassenger[];
  payment_info?: PaymentInfoRetry;
}

export interface ResponseError {
  description?: string;
  error_code: string;
  recoverable?: boolean;
  response_code: number;
  status_text: StatusText;
}

export interface RefreshOfferServicesRequest {
  service_ids: string[];
}

export type ReceivedResponseResponse = typeof ReceivedResponseResponse[keyof typeof ReceivedResponseResponse];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReceivedResponseResponse = {
  Received: 'Received',
} as const;

/**
 * "Okey" response
 */
export interface ReceivedResponse {
  response?: ReceivedResponseResponse;
}

export interface PriceWithPriceCode {
  amount: number;
  currency: string;
  /** Price code, a textual description of the charge */
  price_code: string;
}

export interface PriceWithBreakdown {
  amount: number;
  breakdown: PriceWithPriceCode[];
  currency: string;
}

export interface Price {
  amount: number;
  currency: string;
}

/**
 * An enumeration.
 */
export type PollingBookingStatus = typeof PollingBookingStatus[keyof typeof PollingBookingStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PollingBookingStatus = {
  booking_in_progress: 'booking_in_progress',
  cancelled: 'cancelled',
  failed: 'failed',
  price_check_in_progress: 'price_check_in_progress',
  pending: 'pending',
  success: 'success',
} as const;

export interface ServiceBooking {
  booking_id: string;
  booking_type?: ServiceBookingBookingType;
  status: PollingBookingStatus;
  tier_id: TierName;
  total: Price;
}

export type PaymentMethodResponsePaymentMethodsItem = CardPaymentMethod | ApplePayPaymentMethod | UpliftPaymentMethod;

export interface PaymentMethodResponse {
  payment_methods: PaymentMethodResponsePaymentMethodsItem[];
}

/**
 * An enumeration.
 */
export type PaymentMethod = typeof PaymentMethod[keyof typeof PaymentMethod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentMethod = {
  PCI_PROXY_CREDIT_CARD: 'PCI_PROXY_CREDIT_CARD',
  APPLE_PAY: 'APPLE_PAY',
  UPLIFT: 'UPLIFT',
} as const;

export type PaymentInfoRetryPayment = ApplePayPaymentRetry | CardPaymentRetry | B2BPayment;

/**
 * If the request is coming not from browser - origin_url should be provided
 */
export interface PaymentInfoRetry {
  origin_url?: string;
  payment: PaymentInfoRetryPayment;
  payment_method?: PaymentMethod;
}

export type PaymentInfoPayment = ApplePayPayment | CardPayment | B2BPayment;

/**
 * If the request is coming not from browser - origin_url should be provided
 */
export interface PaymentInfo {
  origin_url?: string;
  payment: PaymentInfoPayment;
  payment_method?: PaymentMethod;
}

/**
 * An enumeration.
 */
export type PaxType = typeof PaxType[keyof typeof PaxType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaxType = {
  a: 'a',
  c: 'c',
  i: 'i',
} as const;

/**
 * An enumeration.
 */
export type PaxGender = typeof PaxGender[keyof typeof PaxGender];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaxGender = {
  m: 'm',
  f: 'f',
  x: 'x',
} as const;

export type PassengerFrequentFlyerNumbers = {[key: string]: string};

export interface Passenger {
  date_of_birth?: string;
  expected_type: PaxType;
  first_name?: string;
  frequent_flyer_numbers?: PassengerFrequentFlyerNumbers;
  gender?: PaxGender;
  last_name?: string;
  passenger_id: string;
  passport_country?: string;
  passport_expiration?: string;
  passport_number?: string;
  title?: string;
  traveling_with?: string;
}

export interface PassengerError {
  error_code: string;
  is_recoverable: boolean;
  passengers: Passenger[];
}

export type OrderSummaryResponseBookingsItem = TravelBooking | ServiceBooking;

export interface OrderSummaryResponse {
  billing_address: BillingAddress;
  bookings: OrderSummaryResponseBookingsItem[];
  contact_information: ContactInformation;
  itinerary: SegmentedItineraryWithCoveredConnections;
  passengers: Passenger[];
  secret: string;
  summary: Summary;
}

export interface OfferSeatMap {
  leg_id: string;
  sections: SeatMapSection[];
}

export type OfferResponseErrorsItem = ServerPersesAppsOfferV14SchemasOfferRefreshMismatchError | SwallowedRefreshMismatchError;

export type OfferResponseChargeTransactionsItem = CarrierChargeTransaction | DohopChargeTransaction;

export type OfferResponseAllPaxServicesItem = AdditionalLoungeAccess | DohopService | AdditionalCancellationProtection | AdditionalGroundTransport | AdditionalSecurityFastTrack;

/**
 * An enumeration.
 */
export type OfferRequirement = typeof OfferRequirement[keyof typeof OfferRequirement];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OfferRequirement = {
  passport_required: 'passport_required',
  passport_country_required: 'passport_country_required',
} as const;

export interface OfferResponse {
  all_pax_services: OfferResponseAllPaxServicesItem[];
  billing_address: BillingAddress;
  bundle_groups: BundleGroup[];
  charge_transactions: OfferResponseChargeTransactionsItem[];
  contact_information: ContactInformation;
  errors: OfferResponseErrorsItem[];
  expires_at: string;
  offer_id: string;
  passengers: Passenger[];
  requirements: OfferRequirement[];
  secret: string;
  service_groups: ServiceGroup[];
  summary: Summary;
}

export type OfferBundleServicesItem = IncludedComfortPacks | IncludedLifeMiles | IncludedLuggage | IncludedNameOnlyService;

export type OfferBundleAllServicesItem = IncludedComfortPacks | IncludedLifeMiles | IncludedLuggage | IncludedNameOnlyService;

export interface OfferBundle {
  all_services: OfferBundleAllServicesItem[];
  bundle_codes: string[];
  bundle_id: string;
  is_selected?: boolean;
  price: Price;
  services: OfferBundleServicesItem[];
}

/**
 * Any JSON
 */
export interface NotStructured { [key: string]: any }

export interface NotFoundResponseError {
  description?: string;
  error_code: string;
  recoverable?: boolean;
  response_code: number;
  status_text: StatusText;
}

export interface LineItem {
  amount: string;
  label: string;
}

export type LegGroupSummaryAdditionalServicesItem = AdditionalLuggage | SelectedSeat;

export interface Leg {
  arrival: string;
  departure: string;
  destination: AirportInfo;
  /** ISO 8601 Duration format */
  duration?: string;
  flight_number: string;
  is_outbound: boolean;
  /** Reference to a leg in the itinerary */
  leg_id: string;
  marketing_carrier: Carrier;
  operating_carrier: Carrier;
  origin: AirportInfo;
}

export interface IncludedNameOnlyService {
  bundle_code?: string;
  quantity: number;
  service_class: ServiceClass;
}

export type IncludedLuggageServiceClass = typeof IncludedLuggageServiceClass[keyof typeof IncludedLuggageServiceClass];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IncludedLuggageServiceClass = {
  luggage_cabin: 'luggage_cabin',
  luggage_checked: 'luggage_checked',
  luggage_personal: 'luggage_personal',
} as const;

export interface IncludedLuggage {
  bundle_code?: string;
  dimensions?: Dimensions;
  quantity: number;
  service_class: IncludedLuggageServiceClass;
  weight?: number;
}

export type LegGroupSummaryIncludedServicesItem = IncludedComfortPacks | IncludedLifeMiles | IncludedLuggage | IncludedNameOnlyService;

export interface LegGroupSummary {
  additional_services: LegGroupSummaryAdditionalServicesItem[];
  bundle: BundleSummary;
  included_services: LegGroupSummaryIncludedServicesItem[];
  legs: Leg[];
}

export type IncludedLifeMilesServiceClass = typeof IncludedLifeMilesServiceClass[keyof typeof IncludedLifeMilesServiceClass];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IncludedLifeMilesServiceClass = {
  life_miles: 'life_miles',
} as const;

export interface IncludedLifeMiles {
  bundle_code?: string;
  quantity: number;
  service_class: IncludedLifeMilesServiceClass;
  value: number;
}

export type IncludedComfortPacksServiceClass = typeof IncludedComfortPacksServiceClass[keyof typeof IncludedComfortPacksServiceClass];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IncludedComfortPacksServiceClass = {
  comfort_packs: 'comfort_packs',
} as const;

export interface IncludedComfortPacks {
  bundle_code?: string;
  comfort_pack_name: string;
  quantity: number;
  service_class: IncludedComfortPacksServiceClass;
}

/**
 * An enumeration.
 */
export type ErrorCode = typeof ErrorCode[keyof typeof ErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ErrorCode = {
  server_errorbooking_is_booked_error: 'server_error.booking_is_booked_error',
  client_errorbooking_not_found: 'client_error.booking_not_found',
  server_errorbotrun_failed: 'server_error.botrun_failed',
  server_errorrequest_failedcapabilities: 'server_error.request_failed.capabilities',
  client_errorcard_type_error: 'client_error.card_type_error',
  client_errorexperiment_id_not_found: 'client_error.experiment_id_not_found',
  server_errorfailed_to_create_offer: 'server_error.failed_to_create_offer',
  server_errorfailed_to_save_offer: 'server_error.failed_to_save_offer',
  system_errorflight_not_in_index: 'system_error.flight_not_in_index',
  client_errorillegal_metabooking_action: 'client_error.illegal_metabooking_action',
  client_errorinvalid_consumer_id: 'client_error.invalid_consumer_id',
  client_errorinvalid_input: 'client_error.invalid_input',
  client_errorinvalid_input_for_metabooking_update: 'client_error.invalid_input_for_metabooking_update',
  client_errorinvalid_leg_data: 'client_error.invalid_leg_data',
  client_errorinvalid_leg_string: 'client_error.invalid_leg_string',
  client_errorinvalid_period_data: 'client_error.invalid_period_data',
  client_errorinvalid_secret: 'client_error.invalid_secret',
  client_errorinvalid_service_quantity: 'client_error.invalid_service_quantity',
  server_errormetabookoing_does_not_pass_partner_validation: 'server_error.metabookoing_does_not_pass_partner_validation',
  server_errormetabooking_does_not_support_payment_proxy: 'server_error.metabooking_does_not_support_payment_proxy',
  client_errormetabooking_not_found: 'client_error.metabooking_not_found',
  client_errormetabooking_not_in_booked_state: 'client_error.metabooking_not_in_booked_state',
  server_errormetabooking_is_locked: 'server_error.metabooking_is_locked',
  server_errormissing_payment_info: 'server_error.missing_payment_info',
  server_errorunfinished_bookings: 'server_error.unfinished_bookings',
  client_erroroffer_billing_address_not_found: 'client_error.offer_billing_address_not_found',
  client_errorbilling_address_province_required: 'client_error.billing_address_province_required',
  client_erroroffer_bundle_not_found: 'client_error.offer_bundle_not_found',
  client_erroroffer_bundles_not_found: 'client_error.offer_bundles_not_found',
  client_erroroffer_contact_information_not_found: 'client_error.offer_contact_information_not_found',
  client_erroroffer_not_found: 'client_error.offer_not_found',
  client_erroroffer_passenger_not_found: 'client_error.offer_passenger_not_found',
  client_erroroffer_passengers_not_found: 'client_error.offer_passengers_not_found',
  client_erroroffer_seatmaps_not_found: 'client_error.offer_seatmaps_not_found',
  client_erroroffer_services_not_found: 'client_error.offer_services_not_found',
  client_errororder_successfully_booked: 'client_error.order_successfully_booked',
  client_errororder_passenger_not_found: 'client_error.order_passenger_not_found',
  client_errorpax_types_do_not_match: 'client_error.pax_types_do_not_match',
  server_errorrefresh_service_failed: 'server_error.refresh_service_failed',
  client_errorservice_not_found: 'client_error.service_not_found',
  server_errorunable_to_get_tokenized_card_from_payment_proxy: 'server_error.unable_to_get_tokenized_card_from_payment_proxy',
  server_errorunexpected_error: 'server_error.unexpected_error',
  server_errorunknown_tokenized_card_failure: 'server_error.unknown_tokenized_card_failure',
  client_errorvendor_not_found: 'client_error.vendor_not_found',
} as const;

export interface Error {
  error_code: string;
  is_recoverable: boolean;
}

export type DohopServiceBaseServiceClass = typeof DohopServiceBaseServiceClass[keyof typeof DohopServiceBaseServiceClass];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DohopServiceBaseServiceClass = {
  dohop_service_fee: 'dohop_service_fee',
  dohop_commission_fee: 'dohop_commission_fee',
} as const;

export type DohopServiceBasePrice = PriceWithBreakdown | Price;

export interface DohopServiceBase {
  direction?: string;
  price: DohopServiceBasePrice;
  quantity: number;
  service_class: DohopServiceBaseServiceClass;
  service_id: string;
  tier_id: TierName;
}

export type DohopServiceServiceClass = typeof DohopServiceServiceClass[keyof typeof DohopServiceServiceClass];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DohopServiceServiceClass = {
  dohop_service_fee: 'dohop_service_fee',
  dohop_commission_fee: 'dohop_commission_fee',
} as const;

export type DohopServicePrice = PriceWithBreakdown | Price;

export interface DohopService {
  can_decrement: boolean;
  can_increment: boolean;
  direction?: string;
  price: DohopServicePrice;
  quantity: number;
  service_class: DohopServiceServiceClass;
  service_id: string;
  tier_id: TierName;
}

/**
 * `single_transaction` means that the charge is taken for the whole itinerary and on behalf of all parties envolved in the order
 */
export interface DohopChargeTransaction {
  charge: Price;
  /** Components of the charge */
  components?: ChargeComponent[];
  single_transaction?: boolean;
  tier_id: TierName;
  transaction_type?: string;
}

export interface Dimensions {
  height: number;
  length: number;
  width: number;
}

export type DeeplinkBreakdownItemBookingType = typeof DeeplinkBreakdownItemBookingType[keyof typeof DeeplinkBreakdownItemBookingType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeeplinkBreakdownItemBookingType = {
  travel: 'travel',
  service: 'service',
} as const;

export interface DeeplinkBreakdownItem {
  booking_type: DeeplinkBreakdownItemBookingType;
  price: Price;
}

export interface DeeplinkBreakdown {
  breakdown: DeeplinkBreakdownItem[];
  total: Price;
}

/**
 * A connection that we insure and cover the costs in case of any failures
 */
export interface CoveredConnection {
  from_leg_id: string;
  to_leg_id: string;
}

export interface ContactInformationRequest {
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  phone_country_code: string;
  title: string;
}

export interface ContactInformation {
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  phone_country_code: string;
  title: string;
}

export interface ClientCapabilities {
  three_ds_challenge?: boolean;
  three_ds_dict_value?: boolean;
}

/**
 * Represents a part of the charge. For example, a part of the Dohop charge which is a fee for CancellationProtection, LoungeAccess or or GroundTransport that we charge for.
 */
export interface ChargeComponent {
  price: Price;
  /** Service id of the service that this component is related to */
  service_id?: string;
}

export interface ChargeBreakdownPerPassenger {
  breakdown: PriceWithPriceCode[];
  passenger_id: string;
}

/**
 * We ground the charges per legs which are a part of a single booking
 */
export interface ChargeBreakdownPerLegs {
  legs: Leg[];
  passenger_charge_breakdown: ChargeBreakdownPerPassenger[];
}

export interface ChargeBreakdown {
  breakdown: PriceWithPriceCode[];
}

export interface ChargesBreakdownResponse {
  charge_breakdown_per_legs: ChargeBreakdownPerLegs[];
  dohop_fee_breakdown: ChargeBreakdown;
}

export interface ChangePassengersRequest {
  n_adults?: number;
  /** List of ages separated by "-", for example: 1-2-3 */
  passenger_ages?: string;
  /** List of ages separated by "-", for example: 1-2-3 */
  youngsters_ages?: string;
}

export interface ChangeCurrencyRequest {
  currency: string;
}

export interface ChallengeInfo {
  challenge_id?: number;
  target_bookings: string[];
  threeDS: AdyenThreeDS;
}

export interface CarrierChargeTransaction {
  carrier: string;
  charge: Price;
  /** Components of the charge */
  components?: ChargeComponent[];
  legs: Leg[];
  transaction_type?: string;
}

export interface Carrier {
  code: string;
  name: string;
}

export type CardPaymentRetryPaymentType = typeof CardPaymentRetryPaymentType[keyof typeof CardPaymentRetryPaymentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardPaymentRetryPaymentType = {
  card: 'card',
} as const;

export interface CardPaymentRetry {
  billing_address?: BillingAddress;
  browser_info: BrowserInfo;
  card_info: CardInfo;
  payment_type?: CardPaymentRetryPaymentType;
}

export type CardPaymentMethodChargeTransactionsItem = CarrierChargeTransaction | DohopChargeTransaction;

export interface CardPaymentMethod {
  charge_transactions: CardPaymentMethodChargeTransactionsItem[];
  payment_method?: PaymentMethod;
}

export type CardPaymentPaymentType = typeof CardPaymentPaymentType[keyof typeof CardPaymentPaymentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardPaymentPaymentType = {
  card: 'card',
} as const;

export interface CardInfo {
  card_expiration: string;
  card_type: string;
  client_capabilities?: ClientCapabilities;
  name_on_card: string;
  pci_proxy_tokenized_details?: TokenizedCardDetails;
  pci_proxy_transaction_id?: string;
}

export interface CardPayment {
  billing_address: BillingAddress;
  browser_info: BrowserInfo;
  card_info: CardInfo;
  payment_type?: CardPaymentPaymentType;
}

export interface BundleSummary {
  code: string;
  price: Price;
}

/**
 * A group of bundles for a given journey sector
 */
export interface BundleGroup {
  bundles: OfferBundle[];
  carrier_codes: string[];
  iatas: string[];
  legs: Leg[];
}

export interface BrowserInfo {
  accept_header: string;
  browser_agent: string;
  browser_url: string;
  color_depth: number;
  java_enabled: boolean;
  screen_height: number;
  screen_width: number;
  time_zone_offset: number;
}

export type BookedAdditionalLoungeAccessServiceClass = typeof BookedAdditionalLoungeAccessServiceClass[keyof typeof BookedAdditionalLoungeAccessServiceClass];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BookedAdditionalLoungeAccessServiceClass = {
  lounge_access: 'lounge_access',
} as const;

export type BookedAdditionalLoungeAccessPrice = PriceWithBreakdown | Price;

export interface BookedAdditionalLoungeAccess {
  direction?: string;
  flight_number: string;
  lounge_entry?: string;
  lounge_exit?: string;
  lounge_identifier?: string;
  price: BookedAdditionalLoungeAccessPrice;
  quantity: number;
  reference_id: string;
  service_class: BookedAdditionalLoungeAccessServiceClass;
  service_id: string;
}

export interface BookUpsellOfferResponse {
  order_id: string;
  upsell_order_id: string;
}

export interface BookOfferResponse {
  order_id: string;
}

export interface BookOfferRequest {
  payment_info: PaymentInfo;
}

export interface BillingAddress {
  address: string;
  city: string;
  country: string;
  postal_code: string;
  province: string;
}

export interface BillingAddressError {
  billing_address: BillingAddress;
  error_code: string;
  is_recoverable: boolean;
}

export type B2BPaymentPaymentType = typeof B2BPaymentPaymentType[keyof typeof B2BPaymentPaymentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const B2BPaymentPaymentType = {
  b2b: 'b2b',
} as const;

export interface B2BPayment {
  payment_type: B2BPaymentPaymentType;
}

export type ApplePaySessionResponseData = { [key: string]: any };

export interface ApplePaySessionResponse {
  data: ApplePaySessionResponseData;
}

export interface ApplePaySessionRequest {
  client_url?: string;
}

export type ApplePayPaymentRetryPaymentType = typeof ApplePayPaymentRetryPaymentType[keyof typeof ApplePayPaymentRetryPaymentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApplePayPaymentRetryPaymentType = {
  applepay: 'applepay',
} as const;

export interface ApplePayPaymentRetry {
  applepay_info: ApplePayInfo;
  billing_address?: BillingAddress;
  browser_info: BrowserInfo;
  payment_type?: ApplePayPaymentRetryPaymentType;
}

export interface ApplePayPaymentMethod {
  data: ApplePayData;
  payment_method?: PaymentMethod;
}

export type ApplePayPaymentPaymentType = typeof ApplePayPaymentPaymentType[keyof typeof ApplePayPaymentPaymentType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApplePayPaymentPaymentType = {
  applepay: 'applepay',
} as const;

/**
 * applepay_token is the token we get on event `onpaymentauthorized` from ApplePay SDK. It is event.payment.token.paymentData object json stringified and base64 encoded.
The token data of the token object is signed using PCI Proxy (Datatrans certificates), to decode it would be needed to use Datatrans certificates which we do not own.
Thus this is information is considered as "safe" to process.
 */
export interface ApplePayInfo {
  applepay_token: string;
}

export interface ApplePayPayment {
  applepay_info: ApplePayInfo;
  billing_address: BillingAddress;
  browser_info: BrowserInfo;
  payment_type?: ApplePayPaymentPaymentType;
}

export type ApplePayDataMerchantCapabilitiesItem = typeof ApplePayDataMerchantCapabilitiesItem[keyof typeof ApplePayDataMerchantCapabilitiesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApplePayDataMerchantCapabilitiesItem = {
  supports3DS: 'supports3DS',
} as const;

export interface ApplePayData {
  countryCode: string;
  currencyCode: string;
  lineItems: LineItem[];
  merchantCapabilities?: ApplePayDataMerchantCapabilitiesItem[];
  supportedNetworks: string[];
  total: Total;
}

export interface AirportInfo {
  /** Departure/Arrival airport IATA three letter code. */
  airport_iata: string;
  airport_name: string;
  city_iata: string;
  city_name: string;
  /** Country code ISO 3166 */
  country_code: string;
  country_name: string;
  transport_type: TransportType;
}

export type AdyenThreeDSProviderAction = { [key: string]: any };

export type AdyenThreeDSChallengeType = typeof AdyenThreeDSChallengeType[keyof typeof AdyenThreeDSChallengeType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdyenThreeDSChallengeType = {
  '3ds1': '3ds1',
  '3ds2': '3ds2',
  '3ds2_frictionless': '3ds2_frictionless',
} as const;

export interface AdyenThreeDS {
  challenge_type: AdyenThreeDSChallengeType;
  provider?: string;
  provider_action: AdyenThreeDSProviderAction;
}

export type AdditionalServiceBaseServiceClass = typeof AdditionalServiceBaseServiceClass[keyof typeof AdditionalServiceBaseServiceClass];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdditionalServiceBaseServiceClass = {
  luggage_personal: 'luggage_personal',
  luggage_cabin: 'luggage_cabin',
  luggage_checked: 'luggage_checked',
  dohop_service_fee: 'dohop_service_fee',
  dohop_commission_fee: 'dohop_commission_fee',
  seat_selection: 'seat_selection',
  lounge_access: 'lounge_access',
  cancellation_protection: 'cancellation_protection',
  security_fast_track: 'security_fast_track',
  ground_transport: 'ground_transport',
} as const;

export type AdditionalServiceBasePrice = PriceWithBreakdown | Price;

export interface AdditionalServiceBase {
  direction?: string;
  price: AdditionalServiceBasePrice;
  quantity: number;
  service_class: AdditionalServiceBaseServiceClass;
  service_id: string;
}

export type AdditionalSecurityFastTrackServiceClass = typeof AdditionalSecurityFastTrackServiceClass[keyof typeof AdditionalSecurityFastTrackServiceClass];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdditionalSecurityFastTrackServiceClass = {
  security_fast_track: 'security_fast_track',
} as const;

export type AdditionalSecurityFastTrackPrice = PriceWithBreakdown | Price;

export interface AdditionalSecurityFastTrack {
  airport: string;
  can_decrement: boolean;
  can_increment: boolean;
  direction?: string;
  price: AdditionalSecurityFastTrackPrice;
  quantity: number;
  service_class: AdditionalSecurityFastTrackServiceClass;
  service_id: string;
  ssr_code?: string;
}

export type AdditionalLuggageBreakdownItemServiceClass = typeof AdditionalLuggageBreakdownItemServiceClass[keyof typeof AdditionalLuggageBreakdownItemServiceClass];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdditionalLuggageBreakdownItemServiceClass = {
  luggage_cabin: 'luggage_cabin',
  luggage_checked: 'luggage_checked',
  luggage_personal: 'luggage_personal',
} as const;

export interface AdditionalLuggageBreakdownItem {
  bundle_code?: string;
  dimensions?: Dimensions;
  leg_ids: string[];
  quantity: number;
  service_class: AdditionalLuggageBreakdownItemServiceClass;
  weight?: number;
}

export type AdditionalLuggageServiceClass = typeof AdditionalLuggageServiceClass[keyof typeof AdditionalLuggageServiceClass];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdditionalLuggageServiceClass = {
  luggage_personal: 'luggage_personal',
  luggage_cabin: 'luggage_cabin',
  luggage_checked: 'luggage_checked',
} as const;

export type AdditionalLuggagePrice = PriceWithBreakdown | Price;

export interface AdditionalLuggage {
  alignment_breakdown?: AdditionalLuggageBreakdownItem[];
  can_decrement: boolean;
  can_increment: boolean;
  dimensions?: Dimensions;
  direction?: string;
  passenger_id: string;
  price: AdditionalLuggagePrice;
  quantity: number;
  service_class: AdditionalLuggageServiceClass;
  service_id: string;
  weight?: number;
}

export type AdditionalLoungeAccessServiceClass = typeof AdditionalLoungeAccessServiceClass[keyof typeof AdditionalLoungeAccessServiceClass];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdditionalLoungeAccessServiceClass = {
  lounge_access: 'lounge_access',
} as const;

export type AdditionalLoungeAccessPrice = PriceWithBreakdown | Price;

export interface AdditionalLoungeAccess {
  can_decrement: boolean;
  can_increment: boolean;
  direction?: string;
  flight_number: string;
  lounge_entry?: string;
  lounge_exit?: string;
  lounge_identifier?: string;
  price: AdditionalLoungeAccessPrice;
  quantity: number;
  service_class: AdditionalLoungeAccessServiceClass;
  service_id: string;
}

export type AdditionalGroundTransportServiceClass = typeof AdditionalGroundTransportServiceClass[keyof typeof AdditionalGroundTransportServiceClass];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdditionalGroundTransportServiceClass = {
  ground_transport: 'ground_transport',
} as const;

export type AdditionalGroundTransportPrice = PriceWithBreakdown | Price;

export interface AdditionalGroundTransport {
  can_decrement: boolean;
  can_increment: boolean;
  direction?: string;
  fare_features?: TransportFareFeatures[];
  leg: TransportLeg;
  price: AdditionalGroundTransportPrice;
  provider: string;
  quantity: number;
  service_class: AdditionalGroundTransportServiceClass;
  service_id: string;
  ticket_validity: string;
}

export type AdditionalCancellationProtectionServiceClass = typeof AdditionalCancellationProtectionServiceClass[keyof typeof AdditionalCancellationProtectionServiceClass];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdditionalCancellationProtectionServiceClass = {
  cancellation_protection: 'cancellation_protection',
} as const;

export type AdditionalCancellationProtectionPrice = PriceWithBreakdown | Price;

export interface AdditionalCancellationProtection {
  can_decrement: boolean;
  can_increment: boolean;
  direction?: string;
  price: AdditionalCancellationProtectionPrice;
  provider: string;
  quantity: number;
  quote_id?: string;
  service_class: AdditionalCancellationProtectionServiceClass;
  service_id: string;
}

export type APIErrorErrorsItem = ResponseError | NotFoundResponseError;

export interface APIError {
  errors: APIErrorErrorsItem[];
}





/**
 * @summary Return localized itinerary details for deeplinks version 3.
 */
export const useGetItineraryHook = () => {
        const getItinerary = useCustomInstance<SegmentedItineraryWithCoveredConnections>();

        return (
    params: GetItineraryParams,
 signal?: AbortSignal
) => {
        return getItinerary(
          {url: `/itinerary`, method: 'GET',
        params, signal
    },
          );
        }
      }
    

export const getGetItineraryQueryKey = (params: GetItineraryParams,) => {
    return [`/itinerary`, ...(params ? [params]: [])] as const;
    }

    
export const useGetItineraryQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetItineraryHook>>>, TError = APIError>(params: GetItineraryParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetItineraryHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetItineraryQueryKey(params);

  const getItinerary =  useGetItineraryHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetItineraryHook>>>> = ({ signal }) => getItinerary(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetItineraryHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetItineraryQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetItineraryHook>>>>
export type GetItineraryQueryError = APIError

/**
 * @summary Return localized itinerary details for deeplinks version 3.
 */
export const useGetItinerary = <TData = Awaited<ReturnType<ReturnType<typeof useGetItineraryHook>>>, TError = APIError>(
 params: GetItineraryParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetItineraryHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetItineraryQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Create offer response.
 * @summary Create offer response.
 */
export const useGetNewHook = () => {
        const getNew = useCustomInstance<OfferResponse>();

        return (
    params: GetNewParams,
 signal?: AbortSignal
) => {
        return getNew(
          {url: `/new`, method: 'GET',
        params, signal
    },
          );
        }
      }
    

export const getGetNewQueryKey = (params: GetNewParams,) => {
    return [`/new`, ...(params ? [params]: [])] as const;
    }

    
export const useGetNewQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetNewHook>>>, TError = APIError>(params: GetNewParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNewHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetNewQueryKey(params);

  const getNew =  useGetNewHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetNewHook>>>> = ({ signal }) => getNew(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNewHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetNewQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetNewHook>>>>
export type GetNewQueryError = APIError

/**
 * @summary Create offer response.
 */
export const useGetNew = <TData = Awaited<ReturnType<ReturnType<typeof useGetNewHook>>>, TError = APIError>(
 params: GetNewParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNewHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetNewQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Create offer response.
 * @summary Create offer response.
 */
export const usePostNewHook = () => {
        const postNew = useCustomInstance<OfferResponse>();

        return (
    params: PostNewParams,
 ) => {
        return postNew(
          {url: `/new`, method: 'POST',
        params
    },
          );
        }
      }
    


export const usePostNewMutationOptions = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostNewHook>>>, TError,{params: PostNewParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostNewHook>>>, TError,{params: PostNewParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postNew =  usePostNewHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostNewHook>>>, {params: PostNewParams}> = (props) => {
          const {params} = props ?? {};

          return  postNew(params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostNewMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostNewHook>>>>
    
    export type PostNewMutationError = APIError

    /**
 * @summary Create offer response.
 */
export const usePostNew = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostNewHook>>>, TError,{params: PostNewParams}, TContext>, }
) => {

      const mutationOptions = usePostNewMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get offer response
 * @summary Get offer response
 */
export const useGetOfferIdHook = () => {
        const getOfferId = useCustomInstance<OfferResponse>();

        return (
    offerId: string,
 signal?: AbortSignal
) => {
        return getOfferId(
          {url: `/${offerId}`, method: 'GET', signal
    },
          );
        }
      }
    

export const getGetOfferIdQueryKey = (offerId: string,) => {
    return [`/${offerId}`] as const;
    }

    
export const useGetOfferIdQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetOfferIdHook>>>, TError = APIError>(offerId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOfferIdQueryKey(offerId);

  const getOfferId =  useGetOfferIdHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOfferIdHook>>>> = ({ signal }) => getOfferId(offerId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(offerId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOfferIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOfferIdHook>>>>
export type GetOfferIdQueryError = APIError

/**
 * @summary Get offer response
 */
export const useGetOfferId = <TData = Awaited<ReturnType<ReturnType<typeof useGetOfferIdHook>>>, TError = APIError>(
 offerId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetOfferIdQueryOptions(offerId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Endpoint allows to refresh offer services.
 * @summary Refresh offer services
 */
export const usePostOfferIdServicesRefreshHook = () => {
        const postOfferIdServicesRefresh = useCustomInstance<OfferResponse>();

        return (
    offerId: string,
    refreshOfferServicesRequest: RefreshOfferServicesRequest,
 ) => {
        return postOfferIdServicesRefresh(
          {url: `/${offerId}/services/refresh`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: refreshOfferServicesRequest
    },
          );
        }
      }
    


export const usePostOfferIdServicesRefreshMutationOptions = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdServicesRefreshHook>>>, TError,{offerId: string;data: RefreshOfferServicesRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdServicesRefreshHook>>>, TError,{offerId: string;data: RefreshOfferServicesRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postOfferIdServicesRefresh =  usePostOfferIdServicesRefreshHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostOfferIdServicesRefreshHook>>>, {offerId: string;data: RefreshOfferServicesRequest}> = (props) => {
          const {offerId,data} = props ?? {};

          return  postOfferIdServicesRefresh(offerId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostOfferIdServicesRefreshMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostOfferIdServicesRefreshHook>>>>
    export type PostOfferIdServicesRefreshMutationBody = RefreshOfferServicesRequest
    export type PostOfferIdServicesRefreshMutationError = APIError

    /**
 * @summary Refresh offer services
 */
export const usePostOfferIdServicesRefresh = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdServicesRefreshHook>>>, TError,{offerId: string;data: RefreshOfferServicesRequest}, TContext>, }
) => {

      const mutationOptions = usePostOfferIdServicesRefreshMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Select new bundle.
 * @summary Select new bundle.
 */
export const usePatchOfferIdBundlesHook = () => {
        const patchOfferIdBundles = useCustomInstance<OfferResponse>();

        return (
    offerId: string,
    params: PatchOfferIdBundlesParams,
 ) => {
        return patchOfferIdBundles(
          {url: `/${offerId}/bundles`, method: 'PATCH',
        params
    },
          );
        }
      }
    


export const usePatchOfferIdBundlesMutationOptions = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdBundlesHook>>>, TError,{offerId: string;params: PatchOfferIdBundlesParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdBundlesHook>>>, TError,{offerId: string;params: PatchOfferIdBundlesParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchOfferIdBundles =  usePatchOfferIdBundlesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdBundlesHook>>>, {offerId: string;params: PatchOfferIdBundlesParams}> = (props) => {
          const {offerId,params} = props ?? {};

          return  patchOfferIdBundles(offerId,params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PatchOfferIdBundlesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdBundlesHook>>>>
    
    export type PatchOfferIdBundlesMutationError = APIError

    /**
 * @summary Select new bundle.
 */
export const usePatchOfferIdBundles = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdBundlesHook>>>, TError,{offerId: string;params: PatchOfferIdBundlesParams}, TContext>, }
) => {

      const mutationOptions = usePatchOfferIdBundlesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Update selected quantity for a service.
 * @summary Update selected quantity for a service.
 */
export const usePatchOfferIdServicesHook = () => {
        const patchOfferIdServices = useCustomInstance<OfferResponse>();

        return (
    offerId: string,
    selectServiceRequest: SelectServiceRequest,
 ) => {
        return patchOfferIdServices(
          {url: `/${offerId}/services`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: selectServiceRequest
    },
          );
        }
      }
    


export const usePatchOfferIdServicesMutationOptions = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdServicesHook>>>, TError,{offerId: string;data: SelectServiceRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdServicesHook>>>, TError,{offerId: string;data: SelectServiceRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchOfferIdServices =  usePatchOfferIdServicesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdServicesHook>>>, {offerId: string;data: SelectServiceRequest}> = (props) => {
          const {offerId,data} = props ?? {};

          return  patchOfferIdServices(offerId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PatchOfferIdServicesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdServicesHook>>>>
    export type PatchOfferIdServicesMutationBody = SelectServiceRequest
    export type PatchOfferIdServicesMutationError = APIError

    /**
 * @summary Update selected quantity for a service.
 */
export const usePatchOfferIdServices = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdServicesHook>>>, TError,{offerId: string;data: SelectServiceRequest}, TContext>, }
) => {

      const mutationOptions = usePatchOfferIdServicesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Update passenger information for selected passengers in offer, does not add or remove passengers
 * @summary Update passenger information for offer
 */
export const usePatchOfferIdPassengersHook = () => {
        const patchOfferIdPassengers = useCustomInstance<OfferResponse>();

        return (
    offerId: string,
    updatePassengersRequest: UpdatePassengersRequest,
 ) => {
        return patchOfferIdPassengers(
          {url: `/${offerId}/passengers`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updatePassengersRequest
    },
          );
        }
      }
    


export const usePatchOfferIdPassengersMutationOptions = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdPassengersHook>>>, TError,{offerId: string;data: UpdatePassengersRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdPassengersHook>>>, TError,{offerId: string;data: UpdatePassengersRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchOfferIdPassengers =  usePatchOfferIdPassengersHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdPassengersHook>>>, {offerId: string;data: UpdatePassengersRequest}> = (props) => {
          const {offerId,data} = props ?? {};

          return  patchOfferIdPassengers(offerId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PatchOfferIdPassengersMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdPassengersHook>>>>
    export type PatchOfferIdPassengersMutationBody = UpdatePassengersRequest
    export type PatchOfferIdPassengersMutationError = APIError

    /**
 * @summary Update passenger information for offer
 */
export const usePatchOfferIdPassengers = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdPassengersHook>>>, TError,{offerId: string;data: UpdatePassengersRequest}, TContext>, }
) => {

      const mutationOptions = usePatchOfferIdPassengersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Changes the number of passengers in the offer, calling this method will refresh the offer and remove previous selection.
 * @summary Change number of passengers for offer
 */
export const usePostOfferIdPassengersHook = () => {
        const postOfferIdPassengers = useCustomInstance<OfferResponse>();

        return (
    offerId: string,
    changePassengersRequest: ChangePassengersRequest,
 ) => {
        return postOfferIdPassengers(
          {url: `/${offerId}/passengers`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: changePassengersRequest
    },
          );
        }
      }
    


export const usePostOfferIdPassengersMutationOptions = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdPassengersHook>>>, TError,{offerId: string;data: ChangePassengersRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdPassengersHook>>>, TError,{offerId: string;data: ChangePassengersRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postOfferIdPassengers =  usePostOfferIdPassengersHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostOfferIdPassengersHook>>>, {offerId: string;data: ChangePassengersRequest}> = (props) => {
          const {offerId,data} = props ?? {};

          return  postOfferIdPassengers(offerId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostOfferIdPassengersMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostOfferIdPassengersHook>>>>
    export type PostOfferIdPassengersMutationBody = ChangePassengersRequest
    export type PostOfferIdPassengersMutationError = APIError

    /**
 * @summary Change number of passengers for offer
 */
export const usePostOfferIdPassengers = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdPassengersHook>>>, TError,{offerId: string;data: ChangePassengersRequest}, TContext>, }
) => {

      const mutationOptions = usePostOfferIdPassengersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Update the contact information for the offer.
 * @summary Update contact information for offer
 */
export const usePatchOfferIdContactHook = () => {
        const patchOfferIdContact = useCustomInstance<OfferResponse>();

        return (
    offerId: string,
    contactInformationRequest: ContactInformationRequest,
 ) => {
        return patchOfferIdContact(
          {url: `/${offerId}/contact`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: contactInformationRequest
    },
          );
        }
      }
    


export const usePatchOfferIdContactMutationOptions = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdContactHook>>>, TError,{offerId: string;data: ContactInformationRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdContactHook>>>, TError,{offerId: string;data: ContactInformationRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchOfferIdContact =  usePatchOfferIdContactHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdContactHook>>>, {offerId: string;data: ContactInformationRequest}> = (props) => {
          const {offerId,data} = props ?? {};

          return  patchOfferIdContact(offerId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PatchOfferIdContactMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdContactHook>>>>
    export type PatchOfferIdContactMutationBody = ContactInformationRequest
    export type PatchOfferIdContactMutationError = APIError

    /**
 * @summary Update contact information for offer
 */
export const usePatchOfferIdContact = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdContactHook>>>, TError,{offerId: string;data: ContactInformationRequest}, TContext>, }
) => {

      const mutationOptions = usePatchOfferIdContactMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Update the billing address for the offer.
 * @summary Update billing address for offer
 */
export const usePatchOfferIdBillingAddressHook = () => {
        const patchOfferIdBillingAddress = useCustomInstance<OfferResponse>();

        return (
    offerId: string,
    billingAddress: BillingAddress,
 ) => {
        return patchOfferIdBillingAddress(
          {url: `/${offerId}/billing_address`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: billingAddress
    },
          );
        }
      }
    


export const usePatchOfferIdBillingAddressMutationOptions = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdBillingAddressHook>>>, TError,{offerId: string;data: BillingAddress}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdBillingAddressHook>>>, TError,{offerId: string;data: BillingAddress}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchOfferIdBillingAddress =  usePatchOfferIdBillingAddressHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdBillingAddressHook>>>, {offerId: string;data: BillingAddress}> = (props) => {
          const {offerId,data} = props ?? {};

          return  patchOfferIdBillingAddress(offerId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PatchOfferIdBillingAddressMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdBillingAddressHook>>>>
    export type PatchOfferIdBillingAddressMutationBody = BillingAddress
    export type PatchOfferIdBillingAddressMutationError = APIError

    /**
 * @summary Update billing address for offer
 */
export const usePatchOfferIdBillingAddress = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOfferIdBillingAddressHook>>>, TError,{offerId: string;data: BillingAddress}, TContext>, }
) => {

      const mutationOptions = usePatchOfferIdBillingAddressMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * 
            Returns partial breakdown of carrier charges.
            Partial means that it doesn't list all the elements,
            but only a subset, sum of which doesn't equal total.
        
 * @summary Get passenger charge breakdown
 */
export const useGetOfferIdChargesHook = () => {
        const getOfferIdCharges = useCustomInstance<ChargesBreakdownResponse>();

        return (
    offerId: string,
 signal?: AbortSignal
) => {
        return getOfferIdCharges(
          {url: `/${offerId}/charges`, method: 'GET', signal
    },
          );
        }
      }
    

export const getGetOfferIdChargesQueryKey = (offerId: string,) => {
    return [`/${offerId}/charges`] as const;
    }

    
export const useGetOfferIdChargesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetOfferIdChargesHook>>>, TError = APIError>(offerId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdChargesHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOfferIdChargesQueryKey(offerId);

  const getOfferIdCharges =  useGetOfferIdChargesHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOfferIdChargesHook>>>> = ({ signal }) => getOfferIdCharges(offerId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(offerId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdChargesHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOfferIdChargesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOfferIdChargesHook>>>>
export type GetOfferIdChargesQueryError = APIError

/**
 * @summary Get passenger charge breakdown
 */
export const useGetOfferIdCharges = <TData = Awaited<ReturnType<ReturnType<typeof useGetOfferIdChargesHook>>>, TError = APIError>(
 offerId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdChargesHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetOfferIdChargesQueryOptions(offerId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Return charges from the deeplink in a single currency.
 */
export const useGetDeeplinkChargeBreakdownHook = () => {
        const getDeeplinkChargeBreakdown = useCustomInstance<DeeplinkBreakdown>();

        return (
    params: GetDeeplinkChargeBreakdownParams,
 signal?: AbortSignal
) => {
        return getDeeplinkChargeBreakdown(
          {url: `/deeplink/charge-breakdown`, method: 'GET',
        params, signal
    },
          );
        }
      }
    

export const getGetDeeplinkChargeBreakdownQueryKey = (params: GetDeeplinkChargeBreakdownParams,) => {
    return [`/deeplink/charge-breakdown`, ...(params ? [params]: [])] as const;
    }

    
export const useGetDeeplinkChargeBreakdownQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetDeeplinkChargeBreakdownHook>>>, TError = APIError>(params: GetDeeplinkChargeBreakdownParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDeeplinkChargeBreakdownHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDeeplinkChargeBreakdownQueryKey(params);

  const getDeeplinkChargeBreakdown =  useGetDeeplinkChargeBreakdownHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetDeeplinkChargeBreakdownHook>>>> = ({ signal }) => getDeeplinkChargeBreakdown(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDeeplinkChargeBreakdownHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDeeplinkChargeBreakdownQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetDeeplinkChargeBreakdownHook>>>>
export type GetDeeplinkChargeBreakdownQueryError = APIError

/**
 * @summary Return charges from the deeplink in a single currency.
 */
export const useGetDeeplinkChargeBreakdown = <TData = Awaited<ReturnType<ReturnType<typeof useGetDeeplinkChargeBreakdownHook>>>, TError = APIError>(
 params: GetDeeplinkChargeBreakdownParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDeeplinkChargeBreakdownHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetDeeplinkChargeBreakdownQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Select a seat.
 * @summary Select a seat.
 */
export const usePostOfferIdSeatsHook = () => {
        const postOfferIdSeats = useCustomInstance<OfferResponse>();

        return (
    offerId: string,
    selectSeatRequest: SelectSeatRequest,
 ) => {
        return postOfferIdSeats(
          {url: `/${offerId}/seats`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: selectSeatRequest
    },
          );
        }
      }
    


export const usePostOfferIdSeatsMutationOptions = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdSeatsHook>>>, TError,{offerId: string;data: SelectSeatRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdSeatsHook>>>, TError,{offerId: string;data: SelectSeatRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postOfferIdSeats =  usePostOfferIdSeatsHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostOfferIdSeatsHook>>>, {offerId: string;data: SelectSeatRequest}> = (props) => {
          const {offerId,data} = props ?? {};

          return  postOfferIdSeats(offerId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostOfferIdSeatsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostOfferIdSeatsHook>>>>
    export type PostOfferIdSeatsMutationBody = SelectSeatRequest
    export type PostOfferIdSeatsMutationError = APIError

    /**
 * @summary Select a seat.
 */
export const usePostOfferIdSeats = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdSeatsHook>>>, TError,{offerId: string;data: SelectSeatRequest}, TContext>, }
) => {

      const mutationOptions = usePostOfferIdSeatsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get seatmap by leg id
 * @summary Get seatmap by leg id
 */
export const useGetOfferIdSeatmapLegIdHook = () => {
        const getOfferIdSeatmapLegId = useCustomInstance<OfferSeatMap>();

        return (
    offerId: string,
    legId: string,
 signal?: AbortSignal
) => {
        return getOfferIdSeatmapLegId(
          {url: `/${offerId}/seatmap/${legId}`, method: 'GET', signal
    },
          );
        }
      }
    

export const getGetOfferIdSeatmapLegIdQueryKey = (offerId: string,
    legId: string,) => {
    return [`/${offerId}/seatmap/${legId}`] as const;
    }

    
export const useGetOfferIdSeatmapLegIdQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetOfferIdSeatmapLegIdHook>>>, TError = APIError>(offerId: string,
    legId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdSeatmapLegIdHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOfferIdSeatmapLegIdQueryKey(offerId,legId);

  const getOfferIdSeatmapLegId =  useGetOfferIdSeatmapLegIdHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOfferIdSeatmapLegIdHook>>>> = ({ signal }) => getOfferIdSeatmapLegId(offerId,legId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(offerId && legId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdSeatmapLegIdHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOfferIdSeatmapLegIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOfferIdSeatmapLegIdHook>>>>
export type GetOfferIdSeatmapLegIdQueryError = APIError

/**
 * @summary Get seatmap by leg id
 */
export const useGetOfferIdSeatmapLegId = <TData = Awaited<ReturnType<ReturnType<typeof useGetOfferIdSeatmapLegIdHook>>>, TError = APIError>(
 offerId: string,
    legId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdSeatmapLegIdHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetOfferIdSeatmapLegIdQueryOptions(offerId,legId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Change display currency for the basket. May also change the currency used in transactions. Note that this doesn not guarantee that all charges will happen in the selected currency
 * @summary Change currency of an offer
 */
export const usePostOfferIdCurrencyHook = () => {
        const postOfferIdCurrency = useCustomInstance<OfferResponse>();

        return (
    offerId: string,
    changeCurrencyRequest: ChangeCurrencyRequest,
 ) => {
        return postOfferIdCurrency(
          {url: `/${offerId}/currency`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: changeCurrencyRequest
    },
          );
        }
      }
    


export const usePostOfferIdCurrencyMutationOptions = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdCurrencyHook>>>, TError,{offerId: string;data: ChangeCurrencyRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdCurrencyHook>>>, TError,{offerId: string;data: ChangeCurrencyRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postOfferIdCurrency =  usePostOfferIdCurrencyHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostOfferIdCurrencyHook>>>, {offerId: string;data: ChangeCurrencyRequest}> = (props) => {
          const {offerId,data} = props ?? {};

          return  postOfferIdCurrency(offerId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostOfferIdCurrencyMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostOfferIdCurrencyHook>>>>
    export type PostOfferIdCurrencyMutationBody = ChangeCurrencyRequest
    export type PostOfferIdCurrencyMutationError = APIError

    /**
 * @summary Change currency of an offer
 */
export const usePostOfferIdCurrency = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdCurrencyHook>>>, TError,{offerId: string;data: ChangeCurrencyRequest}, TContext>, }
) => {

      const mutationOptions = usePostOfferIdCurrencyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get payment methods available
 */
export const useGetOfferIdPaymentMethodsHook = () => {
        const getOfferIdPaymentMethods = useCustomInstance<PaymentMethodResponse>();

        return (
    offerId: string,
 signal?: AbortSignal
) => {
        return getOfferIdPaymentMethods(
          {url: `/${offerId}/payment_methods`, method: 'GET', signal
    },
          );
        }
      }
    

export const getGetOfferIdPaymentMethodsQueryKey = (offerId: string,) => {
    return [`/${offerId}/payment_methods`] as const;
    }

    
export const useGetOfferIdPaymentMethodsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetOfferIdPaymentMethodsHook>>>, TError = APIError>(offerId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdPaymentMethodsHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOfferIdPaymentMethodsQueryKey(offerId);

  const getOfferIdPaymentMethods =  useGetOfferIdPaymentMethodsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOfferIdPaymentMethodsHook>>>> = ({ signal }) => getOfferIdPaymentMethods(offerId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(offerId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdPaymentMethodsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOfferIdPaymentMethodsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOfferIdPaymentMethodsHook>>>>
export type GetOfferIdPaymentMethodsQueryError = APIError

/**
 * @summary Get payment methods available
 */
export const useGetOfferIdPaymentMethods = <TData = Awaited<ReturnType<ReturnType<typeof useGetOfferIdPaymentMethodsHook>>>, TError = APIError>(
 offerId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOfferIdPaymentMethodsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetOfferIdPaymentMethodsQueryOptions(offerId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get Apple Pay session
 * @summary Get Apple Pay session
 */
export const usePostOfferIdApplepaySessionHook = () => {
        const postOfferIdApplepaySession = useCustomInstance<ApplePaySessionResponse>();

        return (
    offerId: string,
    applePaySessionRequest: ApplePaySessionRequest,
 ) => {
        return postOfferIdApplepaySession(
          {url: `/${offerId}/applepay/session`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: applePaySessionRequest
    },
          );
        }
      }
    


export const usePostOfferIdApplepaySessionMutationOptions = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdApplepaySessionHook>>>, TError,{offerId: string;data: ApplePaySessionRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdApplepaySessionHook>>>, TError,{offerId: string;data: ApplePaySessionRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postOfferIdApplepaySession =  usePostOfferIdApplepaySessionHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostOfferIdApplepaySessionHook>>>, {offerId: string;data: ApplePaySessionRequest}> = (props) => {
          const {offerId,data} = props ?? {};

          return  postOfferIdApplepaySession(offerId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostOfferIdApplepaySessionMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostOfferIdApplepaySessionHook>>>>
    export type PostOfferIdApplepaySessionMutationBody = ApplePaySessionRequest
    export type PostOfferIdApplepaySessionMutationError = APIError

    /**
 * @summary Get Apple Pay session
 */
export const usePostOfferIdApplepaySession = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdApplepaySessionHook>>>, TError,{offerId: string;data: ApplePaySessionRequest}, TContext>, }
) => {

      const mutationOptions = usePostOfferIdApplepaySessionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Book offer as order.
 * @summary Book offer
 */
export const usePostOfferIdBookHook = () => {
        const postOfferIdBook = useCustomInstance<BookOfferResponse>();

        return (
    offerId: string,
    bookOfferRequest: BookOfferRequest,
 ) => {
        return postOfferIdBook(
          {url: `/${offerId}/book`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: bookOfferRequest
    },
          );
        }
      }
    


export const usePostOfferIdBookMutationOptions = <TError = ServerPersesModelsErrorResponseErrorPaymentError | APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdBookHook>>>, TError,{offerId: string;data: BookOfferRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdBookHook>>>, TError,{offerId: string;data: BookOfferRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postOfferIdBook =  usePostOfferIdBookHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostOfferIdBookHook>>>, {offerId: string;data: BookOfferRequest}> = (props) => {
          const {offerId,data} = props ?? {};

          return  postOfferIdBook(offerId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostOfferIdBookMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostOfferIdBookHook>>>>
    export type PostOfferIdBookMutationBody = BookOfferRequest
    export type PostOfferIdBookMutationError = ServerPersesModelsErrorResponseErrorPaymentError | APIError

    /**
 * @summary Book offer
 */
export const usePostOfferIdBook = <TError = ServerPersesModelsErrorResponseErrorPaymentError | APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOfferIdBookHook>>>, TError,{offerId: string;data: BookOfferRequest}, TContext>, }
) => {

      const mutationOptions = usePostOfferIdBookMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Poll an order being booked.
 * @summary Poll the order
 */
export const useGetOrderIdPollHook = () => {
        const getOrderIdPoll = useCustomInstance<PollResponse>();

        return (
    orderId: string,
 signal?: AbortSignal
) => {
        return getOrderIdPoll(
          {url: `/${orderId}/poll`, method: 'GET', signal
    },
          );
        }
      }
    

export const getGetOrderIdPollQueryKey = (orderId: string,) => {
    return [`/${orderId}/poll`] as const;
    }

    
export const useGetOrderIdPollQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrderIdPollHook>>>, TError = APIError>(orderId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrderIdPollHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrderIdPollQueryKey(orderId);

  const getOrderIdPoll =  useGetOrderIdPollHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOrderIdPollHook>>>> = ({ signal }) => getOrderIdPoll(orderId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orderId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrderIdPollHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrderIdPollQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOrderIdPollHook>>>>
export type GetOrderIdPollQueryError = APIError

/**
 * @summary Poll the order
 */
export const useGetOrderIdPoll = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrderIdPollHook>>>, TError = APIError>(
 orderId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrderIdPollHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetOrderIdPollQueryOptions(orderId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Retry book offer as order.
 * @summary Retry book offer
 */
export const usePostOrderIdBookRetryHook = () => {
        const postOrderIdBookRetry = useCustomInstance<BookOfferResponse>();

        return (
    orderId: string,
    retryBookOfferRequest: RetryBookOfferRequest,
 ) => {
        return postOrderIdBookRetry(
          {url: `/${orderId}/book/retry`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: retryBookOfferRequest
    },
          );
        }
      }
    


export const usePostOrderIdBookRetryMutationOptions = <TError = ServerPersesModelsErrorResponseErrorPaymentError | APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOrderIdBookRetryHook>>>, TError,{orderId: string;data: RetryBookOfferRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOrderIdBookRetryHook>>>, TError,{orderId: string;data: RetryBookOfferRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postOrderIdBookRetry =  usePostOrderIdBookRetryHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostOrderIdBookRetryHook>>>, {orderId: string;data: RetryBookOfferRequest}> = (props) => {
          const {orderId,data} = props ?? {};

          return  postOrderIdBookRetry(orderId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostOrderIdBookRetryMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostOrderIdBookRetryHook>>>>
    export type PostOrderIdBookRetryMutationBody = RetryBookOfferRequest
    export type PostOrderIdBookRetryMutationError = ServerPersesModelsErrorResponseErrorPaymentError | APIError

    /**
 * @summary Retry book offer
 */
export const usePostOrderIdBookRetry = <TError = ServerPersesModelsErrorResponseErrorPaymentError | APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOrderIdBookRetryHook>>>, TError,{orderId: string;data: RetryBookOfferRequest}, TContext>, }
) => {

      const mutationOptions = usePostOrderIdBookRetryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get Apple Pay session
 * @summary Get Apple Pay session
 */
export const usePostOrderIdApplepaySessionHook = () => {
        const postOrderIdApplepaySession = useCustomInstance<ApplePaySessionResponse>();

        return (
    orderId: string,
    applePaySessionRequest: ApplePaySessionRequest,
 ) => {
        return postOrderIdApplepaySession(
          {url: `/${orderId}/applepay/session`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: applePaySessionRequest
    },
          );
        }
      }
    


export const usePostOrderIdApplepaySessionMutationOptions = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOrderIdApplepaySessionHook>>>, TError,{orderId: string;data: ApplePaySessionRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOrderIdApplepaySessionHook>>>, TError,{orderId: string;data: ApplePaySessionRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postOrderIdApplepaySession =  usePostOrderIdApplepaySessionHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostOrderIdApplepaySessionHook>>>, {orderId: string;data: ApplePaySessionRequest}> = (props) => {
          const {orderId,data} = props ?? {};

          return  postOrderIdApplepaySession(orderId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostOrderIdApplepaySessionMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostOrderIdApplepaySessionHook>>>>
    export type PostOrderIdApplepaySessionMutationBody = ApplePaySessionRequest
    export type PostOrderIdApplepaySessionMutationError = APIError

    /**
 * @summary Get Apple Pay session
 */
export const usePostOrderIdApplepaySession = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOrderIdApplepaySessionHook>>>, TError,{orderId: string;data: ApplePaySessionRequest}, TContext>, }
) => {

      const mutationOptions = usePostOrderIdApplepaySessionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get payment methods available
 */
export const useGetOrderIdPaymentMethodsHook = () => {
        const getOrderIdPaymentMethods = useCustomInstance<PaymentMethodResponse>();

        return (
    orderId: string,
 signal?: AbortSignal
) => {
        return getOrderIdPaymentMethods(
          {url: `/${orderId}/payment_methods`, method: 'GET', signal
    },
          );
        }
      }
    

export const getGetOrderIdPaymentMethodsQueryKey = (orderId: string,) => {
    return [`/${orderId}/payment_methods`] as const;
    }

    
export const useGetOrderIdPaymentMethodsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrderIdPaymentMethodsHook>>>, TError = APIError>(orderId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrderIdPaymentMethodsHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrderIdPaymentMethodsQueryKey(orderId);

  const getOrderIdPaymentMethods =  useGetOrderIdPaymentMethodsHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOrderIdPaymentMethodsHook>>>> = ({ signal }) => getOrderIdPaymentMethods(orderId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orderId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrderIdPaymentMethodsHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrderIdPaymentMethodsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOrderIdPaymentMethodsHook>>>>
export type GetOrderIdPaymentMethodsQueryError = APIError

/**
 * @summary Get payment methods available
 */
export const useGetOrderIdPaymentMethods = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrderIdPaymentMethodsHook>>>, TError = APIError>(
 orderId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrderIdPaymentMethodsHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetOrderIdPaymentMethodsQueryOptions(orderId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Callback for 3DS
 * @summary Callback for 3DS
 */
export const usePostOrderIdChallengeCallbackHook = () => {
        const postOrderIdChallengeCallback = useCustomInstance<ReceivedResponse>();

        return (
    orderId: string,
    notStructured: NotStructured,
    params?: PostOrderIdChallengeCallbackParams,
 ) => {
        return postOrderIdChallengeCallback(
          {url: `/${orderId}/challenge_callback`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: notStructured,
        params
    },
          );
        }
      }
    


export const usePostOrderIdChallengeCallbackMutationOptions = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOrderIdChallengeCallbackHook>>>, TError,{orderId: string;data: NotStructured;params?: PostOrderIdChallengeCallbackParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOrderIdChallengeCallbackHook>>>, TError,{orderId: string;data: NotStructured;params?: PostOrderIdChallengeCallbackParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postOrderIdChallengeCallback =  usePostOrderIdChallengeCallbackHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostOrderIdChallengeCallbackHook>>>, {orderId: string;data: NotStructured;params?: PostOrderIdChallengeCallbackParams}> = (props) => {
          const {orderId,data,params} = props ?? {};

          return  postOrderIdChallengeCallback(orderId,data,params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostOrderIdChallengeCallbackMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostOrderIdChallengeCallbackHook>>>>
    export type PostOrderIdChallengeCallbackMutationBody = NotStructured
    export type PostOrderIdChallengeCallbackMutationError = APIError

    /**
 * @summary Callback for 3DS
 */
export const usePostOrderIdChallengeCallback = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOrderIdChallengeCallbackHook>>>, TError,{orderId: string;data: NotStructured;params?: PostOrderIdChallengeCallbackParams}, TContext>, }
) => {

      const mutationOptions = usePostOrderIdChallengeCallbackMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Creates a new upsell offer for an existing order, returns a list of upsell services available for the given order
 */
export const useGetOrderIdUpsellNewHook = () => {
        const getOrderIdUpsellNew = useCustomInstance<UpsellOfferResponse>();

        return (
    orderId: string,
    params: GetOrderIdUpsellNewParams,
 signal?: AbortSignal
) => {
        return getOrderIdUpsellNew(
          {url: `/${orderId}/upsell/new`, method: 'GET',
        params, signal
    },
          );
        }
      }
    

export const getGetOrderIdUpsellNewQueryKey = (orderId: string,
    params: GetOrderIdUpsellNewParams,) => {
    return [`/${orderId}/upsell/new`, ...(params ? [params]: [])] as const;
    }

    
export const useGetOrderIdUpsellNewQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrderIdUpsellNewHook>>>, TError = APIError>(orderId: string,
    params: GetOrderIdUpsellNewParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrderIdUpsellNewHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrderIdUpsellNewQueryKey(orderId,params);

  const getOrderIdUpsellNew =  useGetOrderIdUpsellNewHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOrderIdUpsellNewHook>>>> = ({ signal }) => getOrderIdUpsellNew(orderId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orderId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrderIdUpsellNewHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrderIdUpsellNewQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOrderIdUpsellNewHook>>>>
export type GetOrderIdUpsellNewQueryError = APIError

/**
 * @summary Creates a new upsell offer for an existing order, returns a list of upsell services available for the given order
 */
export const useGetOrderIdUpsellNew = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrderIdUpsellNewHook>>>, TError = APIError>(
 orderId: string,
    params: GetOrderIdUpsellNewParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrderIdUpsellNewHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetOrderIdUpsellNewQueryOptions(orderId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get upsell offer
 */
export const useGetOrderIdUpsellHook = () => {
        const getOrderIdUpsell = useCustomInstance<UpsellOfferResponse>();

        return (
    orderId: string,
    params: GetOrderIdUpsellParams,
 signal?: AbortSignal
) => {
        return getOrderIdUpsell(
          {url: `/${orderId}/upsell`, method: 'GET',
        params, signal
    },
          );
        }
      }
    

export const getGetOrderIdUpsellQueryKey = (orderId: string,
    params: GetOrderIdUpsellParams,) => {
    return [`/${orderId}/upsell`, ...(params ? [params]: [])] as const;
    }

    
export const useGetOrderIdUpsellQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrderIdUpsellHook>>>, TError = APIError>(orderId: string,
    params: GetOrderIdUpsellParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrderIdUpsellHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrderIdUpsellQueryKey(orderId,params);

  const getOrderIdUpsell =  useGetOrderIdUpsellHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOrderIdUpsellHook>>>> = ({ signal }) => getOrderIdUpsell(orderId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orderId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrderIdUpsellHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrderIdUpsellQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOrderIdUpsellHook>>>>
export type GetOrderIdUpsellQueryError = APIError

/**
 * @summary Get upsell offer
 */
export const useGetOrderIdUpsell = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrderIdUpsellHook>>>, TError = APIError>(
 orderId: string,
    params: GetOrderIdUpsellParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrderIdUpsellHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetOrderIdUpsellQueryOptions(orderId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update selected quantity for a service in upsell offer.
 */
export const usePatchOrderIdUpsellServicesHook = () => {
        const patchOrderIdUpsellServices = useCustomInstance<UpsellOfferResponse>();

        return (
    orderId: string,
    selectServiceRequest: SelectServiceRequest,
    params: PatchOrderIdUpsellServicesParams,
 ) => {
        return patchOrderIdUpsellServices(
          {url: `/${orderId}/upsell/services`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: selectServiceRequest,
        params
    },
          );
        }
      }
    


export const usePatchOrderIdUpsellServicesMutationOptions = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOrderIdUpsellServicesHook>>>, TError,{orderId: string;data: SelectServiceRequest;params: PatchOrderIdUpsellServicesParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOrderIdUpsellServicesHook>>>, TError,{orderId: string;data: SelectServiceRequest;params: PatchOrderIdUpsellServicesParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const patchOrderIdUpsellServices =  usePatchOrderIdUpsellServicesHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePatchOrderIdUpsellServicesHook>>>, {orderId: string;data: SelectServiceRequest;params: PatchOrderIdUpsellServicesParams}> = (props) => {
          const {orderId,data,params} = props ?? {};

          return  patchOrderIdUpsellServices(orderId,data,params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PatchOrderIdUpsellServicesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePatchOrderIdUpsellServicesHook>>>>
    export type PatchOrderIdUpsellServicesMutationBody = SelectServiceRequest
    export type PatchOrderIdUpsellServicesMutationError = APIError

    /**
 * @summary Update selected quantity for a service in upsell offer.
 */
export const usePatchOrderIdUpsellServices = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePatchOrderIdUpsellServicesHook>>>, TError,{orderId: string;data: SelectServiceRequest;params: PatchOrderIdUpsellServicesParams}, TContext>, }
) => {

      const mutationOptions = usePatchOrderIdUpsellServicesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Retry book upsell offer as order.
 * @summary Retry book upsell offer
 */
export const usePostOrderIdUpsellBookUpsellOrderIdRetryHook = () => {
        const postOrderIdUpsellBookUpsellOrderIdRetry = useCustomInstance<BookUpsellOfferResponse>();

        return (
    orderId: string,
    upsellOrderId: string,
    bookOfferRequest: BookOfferRequest,
 ) => {
        return postOrderIdUpsellBookUpsellOrderIdRetry(
          {url: `/${orderId}/upsell/book/${upsellOrderId}/retry`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: bookOfferRequest
    },
          );
        }
      }
    


export const usePostOrderIdUpsellBookUpsellOrderIdRetryMutationOptions = <TError = ServerPersesModelsErrorResponseErrorPaymentError | APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOrderIdUpsellBookUpsellOrderIdRetryHook>>>, TError,{orderId: string;upsellOrderId: string;data: BookOfferRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOrderIdUpsellBookUpsellOrderIdRetryHook>>>, TError,{orderId: string;upsellOrderId: string;data: BookOfferRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postOrderIdUpsellBookUpsellOrderIdRetry =  usePostOrderIdUpsellBookUpsellOrderIdRetryHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostOrderIdUpsellBookUpsellOrderIdRetryHook>>>, {orderId: string;upsellOrderId: string;data: BookOfferRequest}> = (props) => {
          const {orderId,upsellOrderId,data} = props ?? {};

          return  postOrderIdUpsellBookUpsellOrderIdRetry(orderId,upsellOrderId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostOrderIdUpsellBookUpsellOrderIdRetryMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostOrderIdUpsellBookUpsellOrderIdRetryHook>>>>
    export type PostOrderIdUpsellBookUpsellOrderIdRetryMutationBody = BookOfferRequest
    export type PostOrderIdUpsellBookUpsellOrderIdRetryMutationError = ServerPersesModelsErrorResponseErrorPaymentError | APIError

    /**
 * @summary Retry book upsell offer
 */
export const usePostOrderIdUpsellBookUpsellOrderIdRetry = <TError = ServerPersesModelsErrorResponseErrorPaymentError | APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOrderIdUpsellBookUpsellOrderIdRetryHook>>>, TError,{orderId: string;upsellOrderId: string;data: BookOfferRequest}, TContext>, }
) => {

      const mutationOptions = usePostOrderIdUpsellBookUpsellOrderIdRetryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Book upsell offer.
 */
export const usePostOrderIdUpsellBookHook = () => {
        const postOrderIdUpsellBook = useCustomInstance<BookUpsellOfferResponse>();

        return (
    orderId: string,
    bookOfferRequest: BookOfferRequest,
    params?: PostOrderIdUpsellBookParams,
 ) => {
        return postOrderIdUpsellBook(
          {url: `/${orderId}/upsell/book`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: bookOfferRequest,
        params
    },
          );
        }
      }
    


export const usePostOrderIdUpsellBookMutationOptions = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOrderIdUpsellBookHook>>>, TError,{orderId: string;data: BookOfferRequest;params?: PostOrderIdUpsellBookParams}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOrderIdUpsellBookHook>>>, TError,{orderId: string;data: BookOfferRequest;params?: PostOrderIdUpsellBookParams}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      const postOrderIdUpsellBook =  usePostOrderIdUpsellBookHook()


      const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePostOrderIdUpsellBookHook>>>, {orderId: string;data: BookOfferRequest;params?: PostOrderIdUpsellBookParams}> = (props) => {
          const {orderId,data,params} = props ?? {};

          return  postOrderIdUpsellBook(orderId,data,params,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostOrderIdUpsellBookMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePostOrderIdUpsellBookHook>>>>
    export type PostOrderIdUpsellBookMutationBody = BookOfferRequest
    export type PostOrderIdUpsellBookMutationError = APIError

    /**
 * @summary Book upsell offer.
 */
export const usePostOrderIdUpsellBook = <TError = APIError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePostOrderIdUpsellBookHook>>>, TError,{orderId: string;data: BookOfferRequest;params?: PostOrderIdUpsellBookParams}, TContext>, }
) => {

      const mutationOptions = usePostOrderIdUpsellBookMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Poll upsell offer.
 */
export const useGetOrderIdUpsellUpsellOrderIdPollHook = () => {
        const getOrderIdUpsellUpsellOrderIdPoll = useCustomInstance<ServerPersesAppsOfferV14SchemasUpsellPollResponse>();

        return (
    orderId: string,
    upsellOrderId: string,
    params?: GetOrderIdUpsellUpsellOrderIdPollParams,
 signal?: AbortSignal
) => {
        return getOrderIdUpsellUpsellOrderIdPoll(
          {url: `/${orderId}/upsell/${upsellOrderId}/poll`, method: 'GET',
        params, signal
    },
          );
        }
      }
    

export const getGetOrderIdUpsellUpsellOrderIdPollQueryKey = (orderId: string,
    upsellOrderId: string,
    params?: GetOrderIdUpsellUpsellOrderIdPollParams,) => {
    return [`/${orderId}/upsell/${upsellOrderId}/poll`, ...(params ? [params]: [])] as const;
    }

    
export const useGetOrderIdUpsellUpsellOrderIdPollQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrderIdUpsellUpsellOrderIdPollHook>>>, TError = APIError>(orderId: string,
    upsellOrderId: string,
    params?: GetOrderIdUpsellUpsellOrderIdPollParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrderIdUpsellUpsellOrderIdPollHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrderIdUpsellUpsellOrderIdPollQueryKey(orderId,upsellOrderId,params);

  const getOrderIdUpsellUpsellOrderIdPoll =  useGetOrderIdUpsellUpsellOrderIdPollHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOrderIdUpsellUpsellOrderIdPollHook>>>> = ({ signal }) => getOrderIdUpsellUpsellOrderIdPoll(orderId,upsellOrderId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(orderId && upsellOrderId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrderIdUpsellUpsellOrderIdPollHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrderIdUpsellUpsellOrderIdPollQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOrderIdUpsellUpsellOrderIdPollHook>>>>
export type GetOrderIdUpsellUpsellOrderIdPollQueryError = APIError

/**
 * @summary Poll upsell offer.
 */
export const useGetOrderIdUpsellUpsellOrderIdPoll = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrderIdUpsellUpsellOrderIdPollHook>>>, TError = APIError>(
 orderId: string,
    upsellOrderId: string,
    params?: GetOrderIdUpsellUpsellOrderIdPollParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrderIdUpsellUpsellOrderIdPollHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetOrderIdUpsellUpsellOrderIdPollQueryOptions(orderId,upsellOrderId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get summary information for an order. Last name or order secret needs to be provided
 * @summary Get summary for an order.
 */
export const useGetSummaryHook = () => {
        const getSummary = useCustomInstance<OrderSummaryResponse>();

        return (
    params: GetSummaryParams,
 signal?: AbortSignal
) => {
        return getSummary(
          {url: `/summary`, method: 'GET',
        params, signal
    },
          );
        }
      }
    

export const getGetSummaryQueryKey = (params: GetSummaryParams,) => {
    return [`/summary`, ...(params ? [params]: [])] as const;
    }

    
export const useGetSummaryQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetSummaryHook>>>, TError = APIError>(params: GetSummaryParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSummaryHook>>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSummaryQueryKey(params);

  const getSummary =  useGetSummaryHook();

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetSummaryHook>>>> = ({ signal }) => getSummary(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSummaryHook>>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSummaryQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetSummaryHook>>>>
export type GetSummaryQueryError = APIError

/**
 * @summary Get summary for an order.
 */
export const useGetSummary = <TData = Awaited<ReturnType<ReturnType<typeof useGetSummaryHook>>>, TError = APIError>(
 params: GetSummaryParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSummaryHook>>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = useGetSummaryQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




